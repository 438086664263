import axios from "axios";
import { getBaseUrl } from "../../utils";
import Cookies from "js-cookie";

let all_soft_link = getBaseUrl() + "/admin/getLoanEligibility";
let add_tag = getBaseUrl() + "admin/tag";
let update_soft_Link_status = getBaseUrl() + "/admin/updateLoanEligibity";
let delete_tag_api = getBaseUrl() + "/admin/deleteSingleLoanEligibility";

// assign student by their id and their faculty's id
let assign_lead = getBaseUrl() + "/user/leadassign";

export const getAllSoft_api = async (data) => {
  let config = {
    method: "get",
    url: all_soft_link,
    // headers: { Authorization: `Bearer : ${Cookies.get("token")}` },
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
  };
  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const add_tag_api = async (data) => {
  let config = {
    method: "post",
    url: add_tag,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };
  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const softLink_status_update_api = async (data, id) => {
  let config = {
    method: "patch",
    url: update_soft_Link_status + `/${id}`,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };
  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const delete_softLink = async (id) => {
  let config = {
    method: "DELETE",
    url: delete_tag_api + `/${id}`,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

//  role assign ti students by by student Id and faculty Id

export const assign_lead_api = async (data) => {
  let config = {
    method: "post",
    url: assign_lead,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
