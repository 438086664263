import axios from "axios";
import Cookies from "js-cookie"
import { getBaseUrl } from "../../utils";

const get_manager = getBaseUrl() + "/admin/employeeUnderManager";

export const getManagerTeam_api = async (data) => {
    let config = {
        method: "get",
        // url: get_manager + `?page=${data.page}&limit=${data.limit}&search=${data.search}`,
        url: get_manager,
        headers: { Authorization: `Bearer ${Cookies.get("token")}`, "Content-Type": "application/json" },
        // data: data,
    };

    try {
        let res = await axios(config);
        return res;
    } catch (error) {
        console.log(error);
        return error.response;
    }
};