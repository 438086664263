import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { graphData_api } from "../api/auth";

const Graph1 = () => {

  const [graphData, setgraphData] = useState([])


  useEffect(() => {
    fetchGraphDataFunc();
  }, [])

  const fetchGraphDataFunc = async () => {
    let temp = {

    }
    const res = await graphData_api()
    if (res.data.status) {
      setgraphData(res.data.data)
    }
  }


  console.log(graphData, "graphData graphData graphData graphData graphData graphData graphData v")




  const leadPotData = [
    {
      leadPotential: 200,
      leads: 3,
      color: "#0071af",
    },
    {
      leadPotential: 400,
      leads: 1,
      color: "#0071af",
    },
    {
      leadPotential: 500,
      leads: 1,
      color: "##0071af",
    },
    {
      leadPotential: 200,
      leads: 1,
      color: "#0071af",
    },
    {
      leadPotential: 800,
      leads: 1,
      color: "#0071af",
    },
    {
      leadPotential: 600,
      leads: 3,
      color: "#0071af",
    },
    {
      leadPotential: 300,
      leads: 3,
      color: "#0071af",
    },
    {
      leadPotential: 300,
      leads: 3,
      color: "#0071af",
    },
    {
      leadPotential: 300,
      leads: 3,
      color: "#0071af",
    },
  ];
  const leadPotData1 = [
    {
      leadPotential: 100,
      leads: 3,
      color: "#2a88c0",
    },
    {
      leadPotential: 800,
      leads: 1,
      color: "#e4a92f",
    },
    {
      leadPotential: 300,
      leads: 1,
      color: "#e4a92f",
    },
    {
      leadPotential: 250,
      leads: 1,
      color: "#e4a92f",
    },
    {
      leadPotential: 550,
      leads: 1,
      color: "#e4a92f",
    },
    {
      leadPotential: 450,
      leads: 3,
      color: "#24ae6d",
    },
    {
      leadPotential: 400,
      leads: 3,
      color: "#24ae6d",
    },
    {
      leadPotential: 400,
      leads: 3,
      color: "#24ae6d",
    },
    {
      leadPotential: 400,
      leads: 3,
      color: "#24ae6d",
    },
  ];

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        // text: "Chart.js Bar Chart",
      },
    },
  };

  const labels = graphData.map((data) => { return data?.monthName })
  const data = {
    labels,
    datasets: [
      {
        label: "Montly Leads Count",
        data: graphData.map((v) => v.totalLeads),
        backgroundColor: "#0071af",
      },
      // {
      //   label: "Rejected",
      //   data: leadPotData1.map((v) => v.leadPotential),
      //   backgroundColor: "#90907a",
      // },
    ],
  };

  return (
    <>
      <Bar options={options} data={data} />
    </>
  );
};

export default Graph1;
