import React, { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { tableCellClasses } from "@mui/material/TableCell";
import s from "./managerDashboard.module.css";
import { useNavigate } from "react-router-dom";
import { Grid, Pagination } from "@mui/material";
import {
    fetchAllLead_api,
    lead_status_api,
    user_delete_api,
    leadStatusUpdate_api,
} from "../api/Lead";
import { notificationHandler } from "../../utils/Notification";
import Loder from "../../Loder/Loder";
import { BiFilter, BiSearch } from "react-icons/bi";
import DataNotFound from "../ErrorPage/DataNotFound";
import DeletePopup from "../Dialogbox/DeletePopup";
import FilterPopup from "../Dialogbox/FilterPopup";
import { getBaseUrl, getBaseUrl2 } from "../../utils";
import { IoMdAdd } from "react-icons/io";
import { IoEyeSharp } from "react-icons/io5";
import { toast } from "react-toastify";
import xlsx from "json-as-xlsx";
import { fetchAllManagerLead_api } from "../api/managerDashboardLead";

const ManagerDashboardLead = () => {
    const navigate = useNavigate();
    const [isLoading, setisLoading] = useState(false);
    const [allManagerLead, setallManagerLead] = useState([]);
    const [pageCount, setpageCount] = useState(1);
    const [deleteId, setdeleteId] = useState("");
    const [deletedialobbox, setdeletedialobbox] = useState(false);
    const [deletename, setdeletename] = useState();
    const [pageLength, setpageLength] = useState();
    const [search, setsearch] = useState("");
    const [filterData, setFilterData] = useState({ startDate: "", endDate: "" })



    const [allSelectedId, setllSelectedId] = useState([]);
    const [isChecked, setisChecked] = useState(false);

    useEffect(() => {
        fetchAllLead_apiFunc();
    }, [pageCount, search]);

    async function fetchAllLead_apiFunc(data) {
        setisLoading(true);
        try {
            const temp = {
                page: pageCount,
                limit: 50,
                searchValue: search.trim(),
                startDate: filterData.startDate,
                endDate: filterData.endDate,

            };
            let res = await fetchAllManagerLead_api(temp);
            if (res.data.status) {
                setallManagerLead(res?.data?.data.Leads);
                setpageLength(res?.data?.data?.totalPages);
                setisLoading(false);
            } else {
                setisLoading(false);
                console.log("status false!");
            }
        } catch (error) {
            console.log(error);
        }
    }

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "var(--clr-theme)",
            color: theme.palette.common.white,
            fontWeight: "bold",
            borderRight: "1px solid #fff",
            overflow: "hidden",
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            border: "none",
            borderLeft: "2px solid #00000011",
            "&:last-child": {
                borderRight: "2px solid #00000011",
            },
        },
    }));
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        borderBottom: "2px solid #00000011",
    }));

    async function deleteuserFunc() {
        setisLoading(true);
        let temp = {
            id: deleteId,
        };

        try {
            let res = await user_delete_api(temp);
            console.log(res);
            if (res.data.status) {
                setisLoading(false);
                setdeletedialobbox(false);
                fetchAllLead_apiFunc();
                notificationHandler({ type: "success", msg: res.data.message });
            } else {
                setisLoading(false);
                notificationHandler({ type: "danger", msg: res.data.message });
            }
        } catch (error) {
            console.log(error);
            notificationHandler({ type: "danger", msg: error.message });
        }
    }

    const leadStatusUpdate = async (e, data, type) => {
        console.log(Boolean(e.target.value,))
        // setisLoading(true);
        let temp = {};
        if (type == "single") {
            temp = {
                ids: [data._id],
                leadStatus: e.target.value
            }
        }
        if (type == "bulk") {
            temp = {
                ids: allSelectedId,
                leadStatus: "Approved"
            }
        }


        try {
            let res = await lead_status_api(temp, data._id);
            console.log(res);
            if (res.data.status) {
                setisLoading(false);
                setllSelectedId([]);
                setisChecked(false)
                fetchAllLead_apiFunc();
                notificationHandler({ type: "success", msg: res.data.message });
            } else {
                setisLoading(false);
                notificationHandler({ type: "danger", msg: res.data.message });
            }
        } catch (error) {
            console.log(error);
            notificationHandler({ type: "danger", msg: error.message });
        }
    };

    // setting for the excel file , in this headings and lenght of ther excel file is described

    let settings = {
        fileName: "Zamoneyrise", // Name of the resulting spreadsheet
        extraLength: 3, // A bigger number means that columns will be wider
        writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
        writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
        RTL: false, // Display the columns from right-to-left (the default value is false)
    };


    //  this code is used to export the data into  excel file
    const exportFunc = (allLeadsData) => {
        if (allLeadsData.length < 1) {
            return toast.error("All lead list is empty!");
        }
        let data = [
            {
                sheet: "Users Data",
                columns: [
                    { label: "ID", value: row => row._id ? row._id : "" },
                    { label: "User Agent", value: row => row?.assignedBy ? row.assignedBy[0]?.fullName : "" },
                    { label: "Consent Serial Number", value: row => row.consentSerialNo ? row.consentSerialNo : "" },
                    { label: "Date", value: row => row.date ? String(row.date).slice(0, 10) : "" },
                    { label: "Time", value: row => row?.date ? new Date(row.date).toLocaleTimeString() : "" },
                    { label: "Employment Type", value: row => row.employmentType ? row.employmentType : "" },
                    { label: "User Name", value: row => row.fullName ? row.fullName : "" },
                    {
                        label: "File Type", value: row => row.urlImageUploads ?
                            row?.urlImageUploads?.map(item => item.title).join(", ")
                            : ""
                    },

                    // return data.map(row => ({
                    //   "File Type": row.urlImageUploads ? row.urlImageUploads.map(item => item.title).join(", ") : ""
                    // }));

                    { label: "Aadhaar Card No", value: row => row.aadhaarCardNo ? row.aadhaarCardNo : "" },
                    { label: "Email", value: row => row.email ? row.email : "" },
                    { label: "Mobile Number", value: row => row.mobileNo ? row.mobileNo : "" },
                    { label: "City", value: row => row.city ? row.city : "" },
                    { label: "Monthly Income", value: row => row.monthlyIncome ? row.monthlyIncome : "" },
                    { label: "State", value: row => row.state ? row.state : "" },
                    { label: "UPI User", value: row => row.upiUser ? row.upiUser : "" },
                    { label: "URL", value: row => row.url ? row.url : "" },
                ],
                content: allLeadsData,
            },
        ];

        let callback = function (sheet) {
            console.log("Download complete:", sheet);
        };
        try {
            xlsx(data, settings, callback);
        } catch (error) {
            console.log(error);
        }
    };


    const handleChange = (type, data) => {
        setFilterData(prevState => ({
            ...prevState,
            [type]: data.split("-").reverse().join("-"),
        }));
    }


    //  These methods is used for change status of lead in bulk

    const handleChecked = (e) => {
        // console.log(e.target.checked, " this is the ckecked on the page of the lead ")
        const isChecked = e.target.checked
        if (isChecked) {
            let allIds = allManagerLead.map((data) => data._id)
            setllSelectedId(allIds)
            setisChecked(true)
        } else {
            setllSelectedId([])
            setisChecked(false)
        }
    }

    const handleSingleSelect = (event, row) => {
        const isChecked = event.target.checked;
        setllSelectedId((prev) =>
            isChecked ? [...prev, row._id] : prev.filter((item) => item !== row._id)
        );
    }

    return (
        <div className="">
            <div className="beat_heading">
                <div className={s["user-list-heading"]}>
                    <div className="user-list-title">
                        <h3>Lead List</h3>
                    </div>
                    <div className={s["user-list-search"]}>
                        <div className={s["search-box"]}>
                            {/* <span style={{ paddingRight: "0.5rem" }}>
                  <BiSearch size={23} />
                </span>
                <input
                  type="text"
                  spellCheck="false"
                  onChange={(e) => {
                    setpageCount(1);
                    setsearch(e.target.value);
                  }}
                  placeholder = "Search users by name.."
                /> */}
                            <div className={s["filter"]}>
                                <span> Start Date:</span>
                                <input type="date" placeholder="select date" name="start date" onChange={(e) => handleChange("startDate", e.target.value)} />
                            </div>
                            <div className={s["filter"]}>
                                <span> End Date:</span>
                                <input type="date" placeholder="select date" name="end date" onChange={(e) => handleChange("endDate", e.target.value)} />
                            </div>
                            <div className={s["filter-btn"]} onClick={() => fetchAllLead_apiFunc()}>
                                <span style={{ width: "5rem" }}>
                                    <BiFilter size={20} />
                                    Filter
                                </span>
                            </div>
                        </div>
                        <div>
                        </div>
                    </div>
                </div>
                <div className={s["fiteration"]}>
                    <div className={s["search-box"]} s>
                        <span style={{ paddingRight: "0.5rem" }}>
                            <BiSearch size={23} />
                        </span>
                        <input
                            type="text"
                            spellCheck="false"
                            onChange={(e) => {
                                setpageCount(1);
                                setsearch(e.target.value);
                            }}
                            placeholder="Search users by mobile number"
                        />
                    </div>
                    <div className={s["filter-btn"]}>
                        <span
                            onClick={() => exportFunc(allManagerLead)}
                            style={{ paddingRight: "2px", paddingTop: "5px" }}>
                            Export to Excel
                        </span>
                    </div>
                    <div className={s["filter-btn"]}>
                        <span
                            onClick={(e) => leadStatusUpdate(e, "null", "bulk")}
                            style={{ paddingRight: "2px", paddingTop: "5px" }}>
                            Bulk Upload
                        </span>
                    </div>
                </div>
                {/* <div
          className={s["title"]}
          onClick={() =>
            navigate("/add-lead", {
              state: {
                pagetype: "Add",
              },
            })
          }
        >
          <IoMdAdd /> Lead
        </div> */}
            </div>
            <div className="beat_table">
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            {/* <StyledTableCell>Image</StyledTableCell> */}
                            <StyledTableCell align="center"> <input type="checkbox" checked={isChecked} onChange={handleChecked}></input> Select All</StyledTableCell>
                            <StyledTableCell align="center">Lead</StyledTableCell>
                            {/* <StyledTableCell align="center">Informations</StyledTableCell> */}
                            <StyledTableCell align="center">File Date</StyledTableCell>
                            <StyledTableCell align="center">File Type</StyledTableCell>
                            <StyledTableCell align="center">Aadhar Pic</StyledTableCell>
                            <StyledTableCell align="center">Status</StyledTableCell>
                            <StyledTableCell align="center">Action</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {allManagerLead?.map((row) => (
                            <StyledTableRow key={row.id}>
                                {/* <StyledTableCell>
                                    {row.profile_image ? (
                                        <img
                                            style={{ height: "2rem", width: "3rem" }}
                                            src={getBaseUrl2() + row.profile_image.url}
                                            alt=""
                                        />
                                    ) : null}
                                </StyledTableCell> */}
                                <StyledTableCell align="center"> <input type="checkbox" checked={allSelectedId.includes(row._id)} onChange={(e) =>
                                    handleSingleSelect(e, row)}></input></StyledTableCell>
                                <StyledTableCell>
                                    <p> <b>Name:</b>{row?.fullName}</p>
                                    <p><b>Email:</b>{row?.email}</p>
                                    <p><b>Phone No.:</b>{row?.mobileNo}</p>
                                    <p><b>Assigned By.:</b>{row?.assignedBy[0].fullName}</p>
                                </StyledTableCell>
                                <StyledTableCell>
                                    <div>
                                        <p>{row.date.slice(0, 10)}</p>
                                        <p>{new Date(row.date).toLocaleTimeString()}</p>
                                    </div>
                                </StyledTableCell>
                                <StyledTableCell>
                                    {row.urlImageUploads.map((data) => (
                                        <div>
                                            <p>{data?.title}:- {data?.accountStatus},</p>
                                        </div>
                                    ))}
                                </StyledTableCell>
                                <StyledTableCell align="center">{<img src={getBaseUrl2() + row?.aadhaarFrontPic} width="50px" height="50px" />}</StyledTableCell>
                                {/* <StyledTableCell align="center">
                  asssssssssssss
                </StyledTableCell> */}
                                <StyledTableCell align="center">
                                    <select
                                        className={`${row?.leadStatus === "Rejected"
                                            ? s.inactive_admin
                                            : row?.leadStatus === "Pending"
                                                ? s.progress_admin
                                                : s.active_admin
                                            } ${s["option"]}`}
                                        style={{
                                            border: "none",
                                            fontSize: "14px",
                                            outline: "none",
                                            color: "black",
                                            fontWeight: "600",
                                            padding: "8px",
                                            cursor: "pointer"
                                        }}
                                        value={row?.leadStatus}
                                        onChange={(e) => leadStatusUpdate(e, row, "single")}
                                    // aria-label="Default select example" 
                                    >
                                        {/* <option defaultValue>{row?.leadId?.follow_up_status}</option> */}
                                        <option value="Pending">Pending</option>
                                        <option value="Rejected">Rejected</option>
                                        <option value="Approved">Approved</option>
                                    </select>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    {/* <span

                  >
                    View
                  </span> */}
                                    <IoEyeSharp size={25}
                                        style={{
                                            color: "var(--clr-primary)",
                                            fontSize: "1rem",
                                            cursor: "pointer",
                                            marginRight: "1rem"
                                        }}
                                        onClick={() =>
                                            navigate("/view-page", {
                                                state: {
                                                    data: row,
                                                    pagetype: "Lead"
                                                }
                                            })
                                        }
                                    />
                                    {/* <CiEdit
                    onClick={() =>
                      navigate("/add-lead", {
                        state: {
                          pagetype: "Edit",
                          data: row,
                        },
                      })
                    }
                    style={{
                      fontSize : "1rem",
                      color: "var(--clr-primary)",
                      marginRight: "0.5rem",
                      cursor: "pointer",
                    }}
                  /> */}
                                    <MdDelete size={25}
                                        onClick={() => {
                                            setdeletedialobbox(true);
                                            setdeletename(row.user_name);
                                            setdeleteId(row._id);
                                        }}
                                        style={{
                                            fontSize: "1rem",
                                            color: "var(--clr-primary)",
                                            cursor: "pointer",
                                        }}
                                    />
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
                {allManagerLead.length <= 0 && <DataNotFound />}
                {allManagerLead?.length > 0 && (
                    <div className={s["pagination"]}>
                        <Pagination
                            count={pageLength}
                            size="large"
                            style={{ color: "#D21903" }}
                            onChange={(e, value) => setpageCount(value)}
                            page={pageCount}
                        />
                    </div>
                )}
            </div>
            <DeletePopup
                open={deletedialobbox}
                name={deletename}
                close={() => setdeletedialobbox(!deletedialobbox)}
                onsubmit={() => {
                    deleteuserFunc();
                }}
            />
            <Loder loading={isLoading} />
        </div>
    );
};

export default ManagerDashboardLead;

{
    /* <div className={s["filter-container"]}>
            <div className={s["filter-left"]}>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={4}>
                  <div className="form-group">
                    <label for="exampleInputEmail1">Start Date</label>
                    <input type="date" placeholder="Role" className="form-control" value={startdate} onChange={(e) => setstartdate(e.target.value)} />
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div className="form-group">
                    <label for="exampleInputEmail1">End Date</label>
                    <input type="date" placeholder="Role" className="form-control" value={enddate} onChange={(e) => setenddate(e.target.value)} />
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div className="form-group">
                    <label for="exampleInputEmail1">Status</label>
                    <select className="form-control" id="exampleFormControlSelect1">
                      <option selected value="">
                        Select
                      </option>
                      <option value="Published">Active</option>
                      <option value="Published">InActive</option>
                    </select>
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className="filter-right">
              <div className={s["filter-btn"]} onClick={() => fetchAllLead_apiFunc()}>
                <span style={{ paddingRight: "2px" }}>
                  <BiFilter size={20} />
                </span>
                Filter
              </div>
            </div>
            <div
              className={s["filter-btn"]}
              onClick={() => {
                setenddate();
                setstartdate();
              }}
            >
              Reset
            </div>
          </div> */
}
