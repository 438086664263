import React from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { getBaseUrl } from "../../utils";

const admin_login = getBaseUrl() + "/admin/login";
const verify_otp = getBaseUrl() + "admin/verify_otp";

export const verify_otp_api = async (data) => {
  try {
    let config = {
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    };

    return await axios.post(verify_otp, data, config);
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

const authapi = async (data) => {
  try {
    let config = {
      headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    };
    return await axios.post(admin_login, data);
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export default authapi;



export const graphData_api = async () => {
  let config = {
    method: "get",
    url: getBaseUrl() + "/admin/monthWiseLeadCount",
    headers: { Authorization: `Bearer ${Cookies.get("token")}` }
  }
  try {
    let res = await axios(config)
    return res;
  } catch (error) {
    console.log(error)
    return error.response;
  }
}
