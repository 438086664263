import React, { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { tableCellClasses } from "@mui/material/TableCell";
import s from "./managerTeam.module.css";
import { useNavigate } from "react-router-dom";
import { Dialog, DialogActions, DialogTitle, DialogContent, Pagination } from "@mui/material";
import { notificationHandler } from "../../utils/Notification";
import Loder from "../../Loder/Loder";
import { BiFilter, BiSearch } from "react-icons/bi";
import DataNotFound from "../ErrorPage/DataNotFound";
import { IoMdAdd } from "react-icons/io";
import { getBaseUrl, getBaseUrl2 } from "../../utils";
import { SlBookOpen } from "react-icons/sl";
import { getManagerTeam_api } from "../api/managerTeam";
import { all } from "axios";

const ManagerTeamList = () => {
    const navigate = useNavigate();
    const [isLoading, setisLoading] = useState(false);
    const [allManager, setallManager] = useState({});
    const [pageCount, setpageCount] = useState(1);
    const [deleteId, setdeleteId] = useState("");
    const [deletePopup, setdeletePopup] = useState(false);
    const [currentGroup, setcurrentGroup] = useState({});
    const [pageLength, setpageLength] = useState();
    const [search, setsearch] = useState("");
    useEffect(() => {
        fetchallManagerTeamFunc();
    }, [pageCount, search]);

    let managerData = { name: "", email: "", phoneNo: "", }

    managerData = {
        name: allManager.fullName,
        email: allManager.email,
        phoneNo: allManager.mobileNo
    }




    async function fetchallManagerTeamFunc(data) {
        setisLoading(true);
        try {
            const temp = {
                page: pageCount,
                limit: 8,
                search: search.trim(),
            };
            let res = await getManagerTeam_api(temp);
            if (res.data.status) {
                setallManager(res?.data?.data);
                setpageLength(res?.data?.data?.total_page);
                setisLoading(false);
            } else {
                setisLoading(false);
                console.log("status false!");
            }
        } catch (error) {
            console.log(error);
        }
    }

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "var(--clr-theme)",
            color: theme.palette.common.white,
            fontWeight: "bold",
            borderRight: "1px solid #fff",
            overflow: "hidden",
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            border: "none",
            borderLeft: "2px solid #00000011",
            "&:last-child": {
                borderRight: "2px solid #00000011",
            },
        },
    }));
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        borderBottom: "2px solid #00000011",
    }));


    return (
        <div className="">
            <div className="beat_heading">
                <div className={s["user-list-heading"]}>
                    <div className="user-list-title">
                        <h3>Manager Data</h3>
                    </div>
                    {/* <div className={s["user-list-search"]}>
            <div className={s["search-box"]}>
              <span style={{ paddingRight: "0.5rem" }}>
                <BiSearch size={23} />
              </span>
              <input
                type="text"
                spellCheck="false"
                onChange={(e) => {
                  setpageCount(1);
                  setsearch(e.target.value);
                }}
                placeholder="Search by name..."
              />
            </div>
            <div className={s["filter-btn"]}>
              <span style={{ paddingRight: "2px" }}>
                <BiFilter size={20} />
              </span>
              Filter
            </div>
          </div> */}
                </div>
                <div className="beat_left">
                    {/* <div
            className={s["title"]}
            onClick={() =>
              navigate("/astrologer-add", {
                state: {
                  pagetype: "Add",
                },
              })
            }
          >
            <IoMdAdd /> Event
          </div> */}
                </div>
            </div>
            <div className={s["manager_data"]}>
                <div className={s["manager_info"]}>
                    <div>
                        <b> Manager Name: </b>
                        <span>{managerData.name}</span>
                    </div>
                    <div>
                        <b>Email: </b>
                        <span>{managerData.email}</span>
                    </div>
                    <div>
                        <b>Mob. No. : </b>
                        <span>{managerData.phoneNo}</span>
                    </div>
                </div>
            </div>
            <div className="beat_table">
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center">Profile</StyledTableCell>
                            <StyledTableCell align="center">Name</StyledTableCell>
                            <StyledTableCell align="center">Phone No.</StyledTableCell>
                            <StyledTableCell align="center"> Number of Leads</StyledTableCell>
                            {/* <StyledTableCell align="center">Action</StyledTableCell> */}
                            {/* <StyledTableCell align="center">Status</StyledTableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {allManager?.employees?.map((row, index) => (
                            <StyledTableRow key={row.id}>
                                <StyledTableCell>
                                    <img src={getBaseUrl2() + row?.profilePic} alt="thumbnail" style={{ width: "50px", height: "50px" }} />
                                </StyledTableCell>
                                <StyledTableCell>{row?.fullName}</StyledTableCell>
                                <StyledTableCell>{row?.mobileNo} </StyledTableCell>
                                {/* <StyledTableCell>{(row?.manager?.employees).length} </StyledTableCell> */}
                                {/* <StyledTableCell>
                  {row?.manager?.employees.map((data,index)=>(
                    <div>
                    </div>
                  ))
                }
                 </StyledTableCell> */}
                                <StyledTableCell align="center">
                                    <SlBookOpen
                                        onClick={() =>
                                            navigate("/manager-employee-team", {
                                                state: {
                                                    pagetype: "Edit",
                                                    data: row?.leads,
                                                },
                                            })
                                        }
                                        style={{
                                            fontSize: "1rem",
                                            color: "var(--clr-theme)",
                                            marginRight: "0.5rem",
                                            cursor: "pointer",
                                        }}
                                    />
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
                {allManager?.length <= 0 && <DataNotFound />}
                {allManager?.length > 0 && (
                    <div className={s["pagination"]}>
                        <Pagination count={pageLength} size="large" style={{ color: "#D21903" }} onChange={(e, value) => setpageCount(value)} page={pageCount} />
                    </div>
                )}
            </div>
            <Dialog open={deletePopup} aria-labelledby="form-dialog-title" maxWidth="xs" fullWidth="true" onClose={() => setdeletePopup(false)}>
                <DialogTitle className={s.dialog_title}>
                    <div>Do you want to delete {currentGroup.name}?</div>
                </DialogTitle>
                <DialogContent className={s.cardpopup_content}>
                    <div style={{ display: "flex", gap: "1rem" }}>
                        <div className={s.employee_gl_popup} onClick={() => setdeletePopup(false)}>
                            Cancel
                        </div>
                        <div className={s.employee_gl_popup_del}>
                            Delete
                        </div>
                    </div>
                </DialogContent>
                <DialogActions></DialogActions>
            </Dialog>
            <Loder loading={isLoading} />
        </div >
    );
};

export default ManagerTeamList;
