import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function ImagePopUp({ imagePopUp, setimagePopUp, imageData }) {

    console.log(imageData, imagePopUp, "eiofhefioufhwuioefiufifueiufh")
    const [open, setOpen] = React.useState(false);
    const handleClose = () => setimagePopUp(false);

    return (
        <div>
            {/* <Button onClick={handleOpen}>Open modal</Button> */}
            <Modal
                open={imagePopUp}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        <img src={imageData} />
                    </Typography>

                </Box>
            </Modal>
        </div>
    );
}
