import React from 'react'
import AddUrl from '../components/Url/AddUrl'

const AddUrlPage = () => {
    return (
        <div>
            <AddUrl />
        </div>
    )
}

export default AddUrlPage
