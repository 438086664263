import React from "react";
import Dashboard from "./pages/Dashboard";
import Profile from "./pages/profile";
import Category from "./pages/FollowUp";
import Banner from "./pages/Banner";
import AddBanner from "./pages/AddBanner";
import Event from "./pages/Manager";
import AppDataEdit from "./components/AppData/AppDataEdit";
import Leads from "./pages/Lead";
import LeadAdd from "./pages/LeadAdd";
import FollowUp from "./pages/FollowUp";
import AddFollowPage from "./pages/AddFollowPage";
import Action from "./components/FollowUp/ActionPage";
import ActionPage from "./components/FollowUp/ActionPage";
import FollowUpHistory from "./components/FollowUp/FollowUpHistory";
import QueryList from "./components/Query/QueryList";
import FaqList from "./components/Faq/FaqList";
import Manager from "./pages/Manager";
import AddFaq from "./components/Faq/AddFaq";
import AddQuery from "./components/Query/AddQuery";
import TeamList from "./components/ManagerList/TeamList";
import UrlPage from "./pages/UrlPage";
import AddUrlPage from "./pages/AddUrlPage";
import Team from "./components/Team/Team";
import AddTeam from "./components/Team/AddTeam";
import ViewPage from "./components/Team/ViewPage";
import ManagerTeamList from "./components/ManagerTeam/ManagerTeam";
import ManagerEmployee from "./components/ManagerTeam/ManagerEmployees";
import SoftLinkList from "./components/SoftLink/SoftLinkList";
import AddSoftLink from "./components/SoftLink/AddSoftLink";
import ImportantLinksList from "./components/ImportantLinks/ImportantLinksList";
import AddImportantLinks from "./components/ImportantLinks/AddImportantLinks";
import MessageList from "./components/Message/MessageList";
import UpdateMessage from "./components/Message/UpdateMessage";
import ManagerSupportList from "./components/ManagerSupport/ManagerSupportList";
import AddManagerSupport from "./components/ManagerSupport/AddManagerSupport";
import ManagerDashboardLead from "./components/ManagerDashboardLead/ManagerDashboardLead";

const routeArray = [
  // { params: undefined, component: <Home1 /> },
  { params: "leads", component: <Leads /> },
  { params: "dashboard", component: <Dashboard /> },
  { params: "add-lead", component: <LeadAdd /> },
  { params: "profile", component: <Profile /> },
  { params: "followup-list", component: <FollowUp /> },
  { params: "add-folowup", component: <AddFollowPage /> },
  { params: "banner-list", component: <Banner /> },
  { params: "add-banner", component: <AddBanner /> },

  // Admin Listing in the zamoneyrise
  { params: "admin-list", component: <Team /> },
  { params: "add_user", component: <AddTeam /> },
  //  view listing of the users
  { params: "view-page", component: <ViewPage /> },

  // Allocated Lead Listing
  { params: "soft-link-list", component: <SoftLinkList /> },
  { params: "add-tag", component: <AddSoftLink /> },

  //App Data
  { params: "app-data", component: <AppDataEdit /> },
  // action page in follow up
  { params: "action-page", component: <ActionPage /> },
  { params: "history-page", component: <FollowUpHistory /> },

  // Imortant Links
  { params: "important-links", component: <ImportantLinksList /> },
  { params: "add-important-links", component: <AddImportantLinks /> },

  // query in loan project
  { params: "query", component: <QueryList /> },
  { params: "add-query", component: <AddQuery /> },

  //  faq in the loan project
  { params: "faq", component: <FaqList /> },
  { params: "add-faq", component: <AddFaq /> },

  // manager listing
  { params: "manager-list", component: <Manager /> },
  { params: "team-list", component: <TeamList /> },
  // Loan MAnagement 
  { params: "url-list", component: <UrlPage /> },
  { params: "add-url", component: <AddUrlPage /> },


  // messages
  { params: "message", component: <MessageList /> },
  { params: "update-message", component: <UpdateMessage /> },




  // this is a seperate listing of team of an individual manager
  { params: "manager-team-list", component: <ManagerTeamList /> },
  { params: "manager-employee-team", component: <ManagerEmployee /> },

  //  this api is used o upate the query in the manager dashboard
  { params: "manager-support", component: <ManagerSupportList /> },
  { params: "update-manager-support", component: <AddManagerSupport /> },


  //  All manager dashoard lead list
  { params: "manager-dashboard-lead", component: <ManagerDashboardLead /> },
];

export default routeArray;
