import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import s from "./message.module.css";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Custombutton from "../../Common/Custombutton";
import { notificationHandler } from "../../utils/Notification";
import { blankValidator, emailValidator } from "../../utils/Validation";
import { useLocation } from "react-router-dom";
import { Card, Grid } from "@mui/material";
import { addFaq_api, updateFaq_api } from "../api/faq";
import { getBaseUrl } from "../../utils";
import Cookies from "js-cookie";
const UpdateMessage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const id = location?.state?.data?._id;
    console.log(location, "location data");
    const [isLoading, setisLoading] = useState(false);
    const [question, setquestion] = useState("");
    const [status, setstatus] = useState("");
    const [priority, setpriority] = useState("");
    const [file, setfile] = useState(null);
    const [message, setmessage] = useState("")
    const pagetype = location.state.pagetype;
    useEffect(() => {
        setmessage(location?.state?.data.offerMessage)
    }, [location]);

    console.log(location.state.data)

    const updateMessage = async () => {
        let temp = {
            message: message
        }
        let config = {
            method: "patch",
            url: getBaseUrl() + "/admin/updateMessage",
            headers: { Authorization: `Bearer ${Cookies.get("token")}` },
            data: temp
        }
        try {
            let res = await axios(config);
            if (res.data.status) {
                navigate("/message");
                notificationHandler({ type: "success", msg: res.data.message });
            } else {
                notificationHandler({ type: "success", msg: res.data.message });
            }
        } catch (error) {
            notificationHandler({ type: "danger", msg: error.message });
            console.log(error);
        }
    };
    return (
        <>
            <div className="">
                <Card className={s["admin_container"]}>
                    <div className={s["title"]} onClick={() => navigate(-1)}>
                        <BiArrowBack />
                        Back
                    </div>
                    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        {/* <Grid item xs={12}>
                            <div className="form-group">
                                <label for="exampleInputEmail1">Question</label>
                                <textarea
                                    type="text"
                                    className="form-control"
                                    name="title" value={question}
                                    onChange={(e) => setquestion(e.target.value)}
                                    placeholder="Question"
                                    rows="2" />

                            </div>
                        </Grid> */}
                        <Grid item xs={12}>
                            <div className="form-group">
                                <label for="exampleInputEmail1">Update Message</label>
                                <textarea
                                    type="text"
                                    className="form-control"
                                    name="message"
                                    value={message}
                                    onChange={(e) => setmessage(e.target.value)}
                                    placeholder="message"
                                    rows="6"
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <div className={s["form-login-btn"]} onClick={() => updateMessage()}>
                        <Custombutton>Submit</Custombutton>
                    </div>
                </Card>
            </div>
        </>
    );
};

export default UpdateMessage;
