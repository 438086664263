import React, { useState, useEffect } from "react";
import s from "./team.module.css";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Custombutton from "../../Common/Custombutton";
import { notificationHandler } from "../../utils/Notification";
import { useLocation } from "react-router-dom";
import { Card, Grid } from "@mui/material";
import { addLanguage_api, updateLanguage_api } from "../api/language";
import RichTextEditor from "react-rte-17";
import { add_feature_media_api, update_Team_api } from "../api/admin";

const AddTeam = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isManager, setisManager] = useState("");
  const pagetype = location.state.pagetype;
  const [password, setPassword] = useState()

  console.log(location?.state?.data, "location state data in the add manager page of the list")
  const id = location?.state?.data._id

  useEffect(() => {
    setisManager(location?.state?.data?.employeeStatus);
  }, [location]);

  const updateUserStatus = async () => {
    let temp = {
      employeeStatus: isManager,
      password: password
    }
    try {
      let res = await update_Team_api(temp, id)
      if (res.data.status) {
        navigate("/admin-list")
        notificationHandler({ type: "success", msg: res.data.message })
      } else {
        notificationHandler({ type: "danger", msg: res.data.message })
      }
    } catch (error) {
      console.log("Status False")
      notificationHandler({ type: "danger", msg: error.message })
    }

  }

  return (
    <>
      <div className="">
        <Card className={s["admin_container"]}>
          <div className={s["title"]} onClick={() => navigate(-1)}>
            <BiArrowBack />
            Back
          </div>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12}>
              <div className="form-group">
                <label for="exampleInputEmail1">Manager Status</label>
                {/* <input
                  type="text"
                  className="form-control"
                  name="first Name"
                  value={isManager}
                  onChange={(e) => setisManager(e.target.value)}
                  placeholder="first name"
                /> */}
                <select
                  className="form-control"
                  value={isManager}
                  onChange={(e) => setisManager(e.target.value)}
                >
                  <option>Update Status</option>
                  <option value="Manager">Manager</option>
                  <option Value="Employee">Employee</option>

                </select>
              </div>

            </Grid>
            <Grid item xs={12}>
              <div className="form-group">
                <label htmlFor="">Set Password</label>
                <input
                  className="form-control"
                  type="number"
                  value={password}
                  placeholder="password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </Grid>
            {/* <Grid item xs={12}>
              <div className="form-group">
                <label for="exampleInputEmail1">Last Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder="last name"
                />
              </div>
            </Grid> */}
            {/* {pagetype === "Edit" ? null : <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Email</label>
                <input
                  type="text"
                  className="form-control"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="email"
                />
              </div>
            </Grid>} */}
            {/* {pagetype === "Edit" ? null : <Grid item xs={6}>
              <div className="form-group">
                <label for="">Phone Number</label>
                <input
                  type="number"
                  className="form-control"
                  name="Phone number"
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                  placeholder="Phone Number"
                />
              </div>
            </Grid>} */}
            {/* {pagetype === "Edit" ? null : <Grid item xs={6}>
              <div className="form-group">
                <label for="exampleInputEmail1">Password</label>
                <input
                  type="number"
                  className="form-control"
                  name="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                />
              </div>
            </Grid>} */}
            {/* {pagetype === "Edit" ? null : <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="">Role</label>
                <select
                  className="form-control"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                >
                  <option value="">select role</option>
                  <option value="admin">Admin</option>
                  <option value="receptionist">Receptionist</option>
                  <option value="teacher">Teacher</option>
                  <option value="dean">Dean</option>
                  <option value="hod">HOD</option>
                </select>
              </div>
            </Grid>} */}
          </Grid>

          <div className={s["form-login-btn"]} onClick={() => updateUserStatus()}>
            <Custombutton>Submit</Custombutton>
          </div>
        </Card>
      </div>
    </>
  );
};

export default AddTeam;
