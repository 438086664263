import Cookies from "js-cookie";
import axios from "axios";
import { getBaseUrl } from "../../utils";

const manager_all_leads = getBaseUrl() + "/admin/leadListOfManagerEmployees";

export const fetchAllManagerLead_api = async (data) => { //--------------------------------------------
    let config = {
        method: "post",
        // url:
        //   lead_list +
        //   `?page=${data.page}&limit=${data.limit}&search=${data.search}`,
        url: manager_all_leads,
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
        data: data,
    };

    try {
        let res = await axios(config);
        return res;
    } catch (error) {
        console.log(error);
        return error.response;
    }
};