import React, { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { tableCellClasses } from "@mui/material/TableCell";
import s from "./team.module.css";
import { useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Pagination,
} from "@mui/material";
import { notificationHandler } from "../../utils/Notification";
import Loder from "../../Loder/Loder";
import { BiFilter, BiSearch } from "react-icons/bi";
import DataNotFound from "../ErrorPage/DataNotFound";
import { IoMdAdd } from "react-icons/io";
import { assignManager_api, get_all_admin_api, update_Team_api } from "../api/admin";
import { getBaseUrl, getBaseUrl2 } from "../../utils";
import axios from "axios";
import { IoEyeSharp } from "react-icons/io5";
import { getManager_api } from "../api/manager";
import ExportToExcel from "./ExportToExcel";

const Team = () => {
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);
  const [allAdmin, setallAdmin] = useState([]);
  const [pageCount, setpageCount] = useState(1);
  const [deleteId, setdeleteId] = useState("");
  const [deletePopup, setdeletePopup] = useState(false);
  const [currentGroup, setcurrentGroup] = useState({});
  const [search, setsearch] = useState("");
  const [pageLength, setpageLength] = useState();
  const [allManager, setallManager] = useState([]);
  const [excelStatus, setexcelStatus] = useState(false)

  console.log(excelStatus, "excel status of the excel sheet generator")

  useEffect(() => {
    fetchallAdminFunc();
  }, [pageCount, search]);

  // console.log(allAdmin, "all media datas");

  const fetchallAdminFunc = async () => {
    setisLoading(true);
    let temp = {
      page: pageCount,
      limit: 50,
      searchValue: search?.trim(),
    };
    try {
      let res = await get_all_admin_api(temp);
      if (res.data.status) {
        setallAdmin(res?.data?.data?.data);
        setpageLength(res?.data?.data?.totalPages)
        setisLoading(false);
      } else {
        setisLoading(false);
        console.log("false Status!");
      }
    } catch (error) {
      setisLoading(false);
      console.log(error);
    }
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "var(--clr-theme)",
      color: theme.palette.common.white,
      fontWeight: "bold",
      borderRight: "1px solid #fff",
      overflow: "hidden",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      border: "none",
      borderLeft: "2px solid #00000011",
      "&:last-child": {
        borderRight: "2px solid #00000011",
      },
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    borderBottom: "2px solid #00000011",
  }));

  const updateUserStatus = async (e, row) => {
    console.log(row, "row in the list")
    let temp = {
      status: e.target.value
    }
    try {
      let res = await update_Team_api(temp, row._id)
      if (res.data.status) {
        fetchallAdminFunc();
        notificationHandler({ type: "success", msg: res.data.message })
      } else {
        notificationHandler({ type: "danger", msg: res.data.message })
      }
    } catch (error) {
      console.log("Status False")
      notificationHandler({ type: "danger", msg: error.message })
    }

  }



  // Manager lsit to assign the manager to employees
  useEffect(() => {
    fetchallManagerFunc();
  }, [pageCount, search]);

  async function fetchallManagerFunc(data) {
    setisLoading(true);
    try {
      const temp = {
        page: pageCount,
        limit: 8,
        search: search.trim(),
      };
      let res = await getManager_api(temp);
      if (res.data.status) {
        setallManager(res?.data?.data);
        setisLoading(false);
      } else {
        setisLoading(false);
        console.log("status false!");
      }
    } catch (error) {
      console.log(error);
    }
  }



  // this function is used to assign manager to an individual employee
  const assignManagerFunction = async (row, managerId) => {
    console.log(row._id, managerId, "this is used ti=os test the id is come in the assin=gn manager api or not")
    let temp = {
      userId: row._id,
      managerId: managerId
    }
    try {
      let res = await assignManager_api(temp, row._id)
      if (res.data.status) {
        fetchallAdminFunc();
        notificationHandler({ type: "success", msg: res.data.message })
      } else {
        notificationHandler({ type: "danger", msg: res.data.message })
      }
    } catch (error) {
      console.log("Status False")
      notificationHandler({ type: "danger", msg: error.message })
    }

  }

  const exportToExcel = () => {
    console.log("export function is hit");
    // return < ExportToExcel data={allAdmin} />
    setexcelStatus(true)
  }





  // console.log(allManager, "all manager listing coming on the team page for assign the manage to an individual employeee................")
  return (
    <>
      <div className="">
        <div className="beat_heading">
          <div className={s["user-list-heading"]}>
            <div className="user-list-title">
              <h3>Users List</h3>
            </div>
            <div className={s["user-list-search"]}>
              <div className={s["search-box"]}>
                <span style={{ paddingRight: "0.5rem" }}>
                  <BiSearch size={23} />
                </span>
                <input
                  type="text"
                  spellCheck="false"
                  onChange={(e) => {
                    setpageCount(1);
                    setsearch(e.target.value);
                  }}
                  placeholder="Search by name and Phone No."
                />
              </div>
              <div className={s["filter-btn"]}>
                <span
                  onClick={() => exportToExcel()}
                  style={{ paddingRight: "2px" }}>
                  Export to Excel
                </span>
              </div>
              {/* <div className={s["filter-btn"]}>
                <span style={{ paddingRight: "2px" }}>
                  <BiFilter size={20} />
                </span>
                Filter
              </div> */}
            </div>
          </div>
          <div className="beat_left">
            {/* <div
              className={s["title"]}
              onClick={() =>
                navigate("/add_user", {
                  state: {
                    pagetype: "Add",
                  },
                })
              }
            >
              <IoMdAdd /> User
            </div> */}
          </div>
        </div>
        <div className="beat_table">
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Users Details</StyledTableCell>
                <StyledTableCell align="center">Bank Details</StyledTableCell>
                <StyledTableCell align="center">Aadhar Information</StyledTableCell>
                {/* <StyledTableCell align="center">Password</StyledTableCell> */}
                <StyledTableCell align="center">Create Date</StyledTableCell>
                <StyledTableCell align="center">Status</StyledTableCell>
                <StyledTableCell align="center">Rank</StyledTableCell>
                <StyledTableCell align="center">Assign Manager</StyledTableCell>
                {/* <StyledTableCell align="center">User Status</StyledTableCell> */}
                <StyledTableCell align="center">Action</StyledTableCell>
              </TableRow>
            </TableHead>
            {/* <div style={{ margin: "0.5rem 0" }}></div> */}
            <TableBody>
              {allAdmin?.map((row) => (
                <StyledTableRow key={row.id}>
                  {/* <StyledTableCell>
                    <img
                      src={row?.thumbnail?.url}
                      style={{ width: "50px", height: "50px" }}
                      alt="thumbnail"
                    />
                  </StyledTableCell> */}
                  <StyledTableCell>
                    <div>
                      <p><b>Name:</b>{row?.fullName}</p>{" "}
                      <p><b>Phone No.:</b>{row?.mobileNo}</p>
                      <p><b>Email:</b>{row?.email}</p>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell>
                    <div>
                      {/* <p><b>Employee Id:</b>{row?.employeeID} </p> */}
                      <p><b>Bank Name:</b>{row?.bankName}</p>{" "}
                      <p><b>Account Holder Name:</b>{row?.accountHolderName}</p>
                      <p><b>Account Number:</b>{row?.accountNumber} </p>
                      <p><b>I.F.S.C Code:</b>{row?.ifscCode} </p>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell>
                    <div>
                      <p><b>Aadhar Number:</b>{row?.aadhaarCardNo}</p>{" "}
                      <p><b>Aadhar Pic:</b><img src={getBaseUrl2() + row?.aadhaarFrontPic} width="50px" height="50px" /> </p>
                    </div>
                  </StyledTableCell>
                  {/* <StyledTableCell>{row?.password} </StyledTableCell> */}
                  <StyledTableCell>
                    {row?.createdAt.slice(0, 10)}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {/* <div
                      style={{ cursor: "pointer" }}
                      // onClick={() => language_status(row)}
                      className={`${row.status === true ? s.active_admin : s.inactive_admin
                        }`}
                    >
                      {row.status === true ? "Active" : "Inactive"}
                    </div> */}
                    <select
                      // class="form-select"
                      className={`${row?.status === "Rejected"
                        ? s.inactive_admin
                        : row?.status === "Pending"
                          ? s.progress_admin
                          : s.active_admin
                        } ${s["option"]}`}
                      style={{
                        border: "none",
                        fontSize: "14px",
                        outline: "none",
                        color: "black",
                        fontWeight: "600",
                        padding: "8px",
                        cursor: "pointer"
                      }}
                      value={row?.status}
                      onChange={(e) => updateUserStatus(e, row)}
                    // aria-label="Default select example" 
                    >
                      {/* <option defaultValue>{row?.leadId?.follow_up_status}</option> */}
                      <option value="Pending">Pending</option>
                      <option value="Rejected">Rejected</option>
                      <option value="Approved">Approved</option>
                    </select>
                  </StyledTableCell>
                  <StyledTableCell>
                    {row?.employeeStatus}
                  </StyledTableCell>
                  <StyledTableCell>
                    {
                      row.employeeStatus === "Manager" ?
                        "Manager" :
                        <select
                          onChange={(e) => assignManagerFunction(row, e.target.value)}
                          value={row?.managerId}
                        >
                          <option>Assign Manager</option>
                          {allManager.map((data) => (
                            <option value={data?.manager?._id}>{data?.manager?.fullName}</option>
                          ))}
                        </select>
                    }
                  </StyledTableCell>

                  <StyledTableCell align="center" >
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", rowGap: "1rem" }} >
                      <IoEyeSharp size={20}
                        onClick={() =>
                          navigate("/view-page", {
                            state: {
                              data: row,
                              pagetype: "User"
                            }
                          })
                        }
                        style={{
                          fontSize: "1rem",
                          color: "var(--clr-primary)",
                          cursor: "pointer"
                        }}
                      />
                      <CiEdit size={20}
                        onClick={() =>
                          navigate("/add_user", {
                            state: {
                              pagetype: "Edit",
                              data: row,
                            },
                          })
                        }
                        style={{
                          fontSize: "1rem",
                          color: "var(--clr-primary)",
                          marginRight: "0.5rem",
                          cursor: "pointer",
                        }}
                      />
                      <MdDelete size={20}
                        onClick={() => {
                          setdeletePopup(true);
                          setcurrentGroup(row);
                          setdeleteId(row._id);
                        }}
                        style={{
                          fontSize: "1rem",
                          color: "var(--clr-primary)",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
          {allAdmin?.length <= 0 && <DataNotFound />}
          {allAdmin?.length > 0 && (
            <div className={s["pagination"]}>
              <Pagination
                count={pageLength}
                size="large"
                style={{ color: "#D21903" }}
                onChange={(e, value) => { setpageCount(value); setexcelStatus(false) }}
                page={pageCount}
              />
            </div>
          )}
        </div>

        <Dialog
          open={deletePopup}
          aria-labelledby="form-dialog-title"
          maxWidth="xs"
          fullWidth="true"
          onClose={() => setdeletePopup(false)}
        >
          <DialogTitle className={s.dialog_title}>
            <div>Do you want to delete {currentGroup.titleEnglish}?</div>
          </DialogTitle>
          <DialogContent className={s.cardpopup_content}>
            <div style={{ display: "flex", gap: "1rem" }}>
              <div
                className={s.employee_gl_popup}
                onClick={() => setdeletePopup(false)}
              >
                Cancel
              </div>
              <div className={s.employee_gl_popup_del}>Delete</div>
            </div>
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
        <Loder loading={isLoading} />
        {excelStatus && < ExportToExcel data={allAdmin} />}
      </div >
    </>
  );
};

export default Team;
