import React, { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { IoMdAdd } from "react-icons/io";
import { CiEdit } from "react-icons/ci";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { tableCellClasses } from "@mui/material/TableCell";
import s from "./query.module.css";
import { useNavigate } from "react-router-dom";
import Loder from "../../Loder/Loder";
import { Dialog, DialogActions, DialogTitle, DialogContent, Pagination } from "@mui/material";
import { notificationHandler } from "../../utils/Notification";
import { BiFilter, BiSearch } from "react-icons/bi";
import DataNotFound from "../ErrorPage/DataNotFound";
import { getBaseUrl2 } from "../../utils";
import { deleteQuery_api, getQuery_api } from "../api/query";

const QueryList = () => {
    const navigate = useNavigate();
    const [isloading, setisLoading] = useState(false);
    const [allQuery, setallQuery] = useState([]);
    const [pageCount, setpageCount] = useState(1);
    const [deleteId, setdeleteId] = useState(null);
    const [deletePopup, setdeletePopup] = useState(false);
    const [currentGroup, setcurrentGroup] = useState({});
    const [pageLength, setpageLength] = useState();
    const [search, setsearch] = useState("");
    useEffect(() => {
        fetchAllQueryFunc();
    }, [pageCount, search]);

    const fetchAllQueryFunc = async (data) => {
        setisLoading(true);
        try {
            const temp = {
                page: pageCount,
                limit: 8,
                search: search.trim(),
            };
            let res = await getQuery_api(temp);
            if (res.data.status) {
                setallQuery(res.data.data);
                setpageLength(res?.data?.data?.totalPages);
                setisLoading(false);
            } else {
                setisLoading(false);
                console.log("status false!");
            }
        } catch (error) {
            console.log(error);
        }
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "var(--clr-theme)",
            color: theme.palette.common.white,
            fontWeight: "bold",
            borderRight: "1px solid #fff",
            overflow: "hidden",
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            border: "none",
            borderLeft: "2px solid #00000011",
            "&:last-child": {
                borderRight: "2px solid #00000011",
            },
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        borderBottom: "2px solid #00000011",
    }));

    async function deleteBrandFunc() {
        setisLoading(true);
        try {
            let res = await deleteQuery_api(deleteId);
            console.log(res);
            setdeletePopup(false);
            setisLoading(false);
            fetchAllQueryFunc();
            if (res.data.status) {
                setdeletePopup(false);
                setisLoading(false);
                fetchAllQueryFunc();
                notificationHandler({ type: "success", msg: res.data.message });
            } else {
                setisLoading(false);
                notificationHandler({ type: "danger", msg: res.data.message });
            }
        } catch (error) {
            console.log(error);
            notificationHandler({ type: "danger", msg: error.message });
        }
    }

    return (
        <div className="container">
            <div className="beat_heading">
                <div className={s["user-list-title"]}>
                    <h3>Query List</h3>
                </div>
                <div className={s["user-list-heading"]}>
                    {/* <div className={s["user-list-title"]}>
                        <div className="beat_left">
                            <div
                                className={s["title"]}
                                onClick={() =>
                                    navigate("/add-faq", {
                                        state: {
                                            pagetype: "Add",
                                        },
                                    })
                                }
                            >
                                <IoMdAdd /> Query
                            </div>
                        </div>
                    </div> */}
                    {/* <div className={s["user-list-search"]}>
                        <div className={s["search-box"]}>
                            <span style={{ paddingRight: "0.5rem" }}>
                                <BiSearch size={23} />
                            </span>
                            <input
                                type="text"
                                spellCheck="false"
                                onChange={(e) => {
                                    setpageCount(1);
                                    setsearch(e.target.value);
                                }}
                                placeholder="Search name..."
                            />
                        </div>
                    </div> */}
                </div>
            </div>
            <div className="beat_table">
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center">Question</StyledTableCell>
                            <StyledTableCell align="center">Answer</StyledTableCell>
                            <StyledTableCell align="center">Action</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {allQuery?.map((row) => (
                            <StyledTableRow key={row?.id}>
                                <StyledTableCell>{row?.query} </StyledTableCell>
                                <StyledTableCell align="center">{row?.answer} </StyledTableCell>

                                <StyledTableCell align="center">
                                    <CiEdit
                                        onClick={() =>
                                            navigate("/add-query", {
                                                state: {
                                                    pagetype: "Edit",
                                                    data: row,
                                                },
                                            })
                                        }
                                        style={{
                                            fontSize: "1rem",
                                            color: "var(--clr-theme)",
                                            marginRight: "0.5rem",
                                            cursor: "pointer",
                                        }}
                                    />
                                    <MdDelete
                                        onClick={() => {
                                            setdeletePopup(true);
                                            setdeleteId(row._id);
                                            setcurrentGroup(row);
                                        }}
                                        style={{ fontSize: "1rem", color: "red", cursor: "pointer" }}
                                    />
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
                {allQuery?.length <= 0 && <DataNotFound />}
                {allQuery?.length > 0 && (
                    <div className={s["pagination"]}>
                        <Pagination count={pageLength} size="large" style={{ color: "#D21903" }} onChange={(e, value) => setpageCount(value)} page={pageCount} />
                    </div>
                )}
            </div>

            <Dialog open={deletePopup} aria-labelledby="form-dialog-title" maxWidth="xs" fullWidth="true" onClose={() => setdeletePopup(false)}>
                <DialogTitle className={s.dialog_title}>
                    <div>Do you want to delete {currentGroup.title}?</div>
                </DialogTitle>
                <DialogContent className={s.cardpopup_content}>
                    <div style={{ display: "flex", gap: "1rem" }}>
                        <div className={s.employee_gl_popup} onClick={() => setdeletePopup(false)}>
                            Cancel
                        </div>
                        <div className={s.employee_gl_popup_del} onClick={deleteBrandFunc}>
                            Delete
                        </div>
                    </div>
                </DialogContent>
                <DialogActions></DialogActions>
            </Dialog>
            <Loder loading={isloading} />
        </div>
    );
};

export default QueryList;
