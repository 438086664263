import Cookies from "js-cookie";
import axios from "axios";
import { getBaseUrl, getBaseUrl2 } from "../../utils";

const getManagerQuery = getBaseUrl() + "/admin/managersEmployeeQuery";
const deleteManageSupport = getBaseUrl() + "/admin/deleteQueryByManager";
const updateManagerSupport = getBaseUrl() + "/admin/updateQueryByManager";

export const updateManagerSupport_api = async (id, data) => {
    let config = {
        method: "patch",
        url: updateManagerSupport + `/${id}`,
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token") || Cookies.get("token")}`, "Content-Type": "application/json" },
        data: {
            // faqId: id,
            // question: data.question,
            answer: data.answer
        }
    };

    try {
        let res = await axios(config);
        return res;
    } catch (error) {
        console.log(error);
        return error.response;
    }
};
export const deleteManageSupport_api = async (data) => {
    let config = {
        method: "delete",
        url: deleteManageSupport + `/${data}`,
        headers: { Authorization: `Bearer Cookies.get("token")}` },
        // data: { faqId: data },
    };

    try {
        let res = await axios(config);
        return res;
    } catch (error) {
        console.log(error);
        return error.response;
    }
};

export const getManagerSupport_api = async (data) => {
    let config = {
        method: "get",
        url: getManagerQuery,
        headers: { Authorization: `Bearer ${Cookies.get("token")}`, "Content-Type": "application/json" },
        data: data,
    };

    try {
        let res = await axios(config);
        return res;
    } catch (error) {
        console.log(error);
        return error.response;
    }
};
