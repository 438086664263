import Cookies from "js-cookie";
import axios from "axios";
import { getBaseUrl, getBaseUrl2 } from "../../utils";

const getQuery = getBaseUrl() + "/admin/getAllQuery";
const deleteQuery = getBaseUrl() + "/admin/deleteSingleQuery";
const addFaq = getBaseUrl2() + "api/admin/faq";
const updateQuery = getBaseUrl() + "/admin/updateSingleQuery";

export const updateQuery_api = async (id, data) => {
    let config = {
        method: "patch",
        url: updateQuery + `/${id}`,
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token") || Cookies.get("token")}` },
        data: {
            // faqId: id,
            // question: data.question,
            answer: data.answer
        },
    };

    try {
        let res = await axios(config);
        return res;
    } catch (error) {
        console.log(error);
        return error.response;
    }
};
export const addFaq_api = async (data) => {
    let config = {
        method: "post",
        url: addFaq,
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token") || Cookies.get("token")}` },
        data: { faq: data },
    };

    try {
        let res = await axios(config);
        return res;
    } catch (error) {
        console.log(error);
        return error.response;
    }
};
export const deleteQuery_api = async (data) => {
    let config = {
        method: "delete",
        url: deleteQuery + `/${data}`,
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
        // data: { faqId: data },
    };

    try {
        let res = await axios(config);
        return res;
    } catch (error) {
        console.log(error);
        return error.response;
    }
};

export const getQuery_api = async (data) => {
    let config = {
        method: "get",
        url: getQuery,
        headers: { Authorization: `Bearer ${Cookies.get("token")}`, "Content-Type": "application/json" },
        data: data,
    };

    try {
        let res = await axios(config);
        return res;
    } catch (error) {
        console.log(error);
        return error.response;
    }
};
