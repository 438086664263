import Cookies from "js-cookie";
import axios from "axios";
import { getBaseUrl, getBaseUrl2 } from "../../utils";

const getUrl = getBaseUrl() + "/admin/getUrl";
// const statusUpdate_banner = getBaseUrl2() + "";
const deleteUrl = getBaseUrl() + "/admin/deleteUrl";
const addUrl = getBaseUrl() + "/admin/createUrl";
const updateUrl = getBaseUrl() + "/admin/updateUrl";

export const updateUrl_api = async (id, data) => {
    let config = {
        method: "patch",
        url: updateUrl + `/${id}`,
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
        data: data,
    };

    try {
        let res = await axios(config);
        return res;
    } catch (error) {
        console.log(error);
        return error.response;
    }
};
export const addUrl_api = async (data) => {
    let config = {
        method: "post",
        url: addUrl,
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token") || Cookies.get("token")}` },
        data: data,
    };
    try {
        let res = await axios(config);
        return res;
    } catch (error) {
        console.log(error);
        return error.response;
    }
};

export const deleteUrl_api = async (data) => {
    let config = {
        method: "delete",
        url: deleteUrl + `/${data}`,
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token") || Cookies.get("token")}` },
        // data: { faqId: data },
    };
    try {
        let res = await axios(config);
        return res;
    } catch (error) {
        console.log(error);
        return error.response;
    }
};

export const getUrl_api = async (data) => {
    let config = {
        method: "get",
        url: getUrl,
        headers: { Authorization: `Bearer ${Cookies.get("token")}`, "Content-Type": "application/json" },
        data: data,
    };

    try {
        let res = await axios(config);
        return res;
    } catch (error) {
        console.log(error);
        return error.response;
    }
};
