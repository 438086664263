import React, { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { IoMdAdd } from "react-icons/io";
import { CiEdit } from "react-icons/ci";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { tableCellClasses } from "@mui/material/TableCell";
import s from "./message.module.css";
import { useNavigate } from "react-router-dom";
import Loder from "../../Loder/Loder";
import { Dialog, DialogActions, DialogTitle, DialogContent, Pagination } from "@mui/material";
import { notificationHandler } from "../../utils/Notification";
import { BiFilter, BiSearch } from "react-icons/bi";
import DataNotFound from "../ErrorPage/DataNotFound";
import { getBaseUrl, getBaseUrl2 } from "../../utils";
import { deleteFaq_api, getFaq_api } from "../api/faq";
import { deleteUrl_api, getUrl_api } from "../api/url";
import axios from "axios";
import Cookies from "js-cookie";

const MessageList = () => {
    const navigate = useNavigate();
    const [isloading, setisLoading] = useState(false);
    const [allMessage, setallMessage] = useState("");
    const [pageCount, setpageCount] = useState(1);
    const [deleteId, setdeleteId] = useState(null);
    const [deletePopup, setdeletePopup] = useState(false);
    const [currentGroup, setcurrentGroup] = useState({});
    const [pageLength, setpageLength] = useState();
    const [search, setsearch] = useState("");
    useEffect(() => {
        fetchAllMessageFunc();
    }, [pageCount, search]);

    const fetchAllMessageFunc = async (data) => {
        setisLoading(true);
        try {
            let config = {
                method: "get",
                url: getBaseUrl() + "/admin/getMessage",
                headers: { Authorization: `Bearer ${Cookies.get("token")}`, "Content-Type": "application/json" },
                // data: data,
            };

            let res = await axios(config);
            if (res.data.status) {
                setallMessage(res?.data?.data);
                setpageLength(res?.data?.data?.totalPages);
                setisLoading(false);
            } else {
                setisLoading(false);
                console.log("status false!");
            }
        } catch (error) {
            console.log(error);
        }
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "var(--clr-theme)",
            color: theme.palette.common.white,
            fontWeight: "bold",
            borderRight: "1px solid #fff",
            overflow: "hidden",
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            border: "none",
            borderLeft: "2px solid #00000011",
            "&:last-child": {
                borderRight: "2px solid #00000011",
            },
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        borderBottom: "2px solid #00000011",
    }));



    return (
        <div className="container">
            <div className="beat_heading">
                <div className={s["user-list-title"]}>
                    <h3>Message</h3>
                </div>
                {/* <div className={s["user-list-heading"]}>
                    <div className={s["user-list-title"]}>
                        <div className="beat_left">
                            <div
                                className={s["title"]}
                                onClick={() =>
                                    navigate("/add-url", {
                                        state: {
                                            pagetype: "Add",
                                        },
                                    })
                                }
                            >
                                <IoMdAdd /> Url
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
            <div className="beat_table">
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center">Message</StyledTableCell>
                            <StyledTableCell align="center">Action</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <StyledTableRow>
                            <StyledTableCell>{allMessage.offerMessage} </StyledTableCell>

                            <StyledTableCell align="center">
                                <CiEdit
                                    onClick={() =>
                                        navigate("/update-message", {
                                            state: {
                                                pagetype: "Edit",
                                                data: allMessage,
                                            },
                                        })
                                    }
                                    style={{
                                        fontSize: "1rem",
                                        color: "var(--clr-theme)",
                                        marginRight: "0.5rem",
                                        cursor: "pointer",
                                    }}
                                />
                            </StyledTableCell>
                        </StyledTableRow>
                    </TableBody>
                </Table>
                {allMessage?.length <= 0 && <DataNotFound />}
                {allMessage?.length > 0 && (
                    <div className={s["pagination"]}>
                        <Pagination count={pageLength} size="large" style={{ color: "#D21903" }} onChange={(e, value) => setpageCount(value)} page={pageCount} />
                    </div>
                )}
            </div>

            <Loder loading={isloading} />
        </div>
    );
};

export default MessageList;
