import React from "react";
import ManagerList from "../components/ManagerList/ManagerListing";
const Manager = () => {
  return (
    <div>
      <ManagerList />
    </div>
  );
};

export default Manager;
