import React from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { getBaseUrl } from "../../utils";

const addevent = getBaseUrl() + "admin/event";
const get_manager = getBaseUrl() + "/admin/allAssigines";
const deleteevent = getBaseUrl() + "admin/event";
const updateevent = getBaseUrl() + "admin/event";

export const updateevent_api = async (data, id) => {
  let config = {
    method: "patch",
    url: updateevent + `/${id}`,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const deleteevent_api = async (id) => {
  let config = {
    method: "delete",
    url: deleteevent + `/${id}`,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
export const addevent_api = async (data) => {
  let config = {
    method: "post",
    url: addevent,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getManager_api = async (data) => {
  let config = {
    method: "get",
    // url: get_manager + `?page=${data.page}&limit=${data.limit}&search=${data.search}`,
    url: get_manager,
    headers: { Authorization: `Bearer ${Cookies.get("token")}`, "Content-Type": "application/json" },
    // data: data,
  };

  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
