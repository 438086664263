import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import s from "./importantLink.module.css";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Custombutton from "../../Common/Custombutton";
import { create_admin_api, create_banner_api, getroll_admin_api, update_admin_api, update_banner_api } from "../api/admin";
import { notificationHandler } from "../../utils/Notification";
import { blankValidator, emailValidator } from "../../utils/Validation";
import { useLocation } from "react-router-dom";
import { Card, Grid } from "@mui/material";
import { addFaq_api, updateFaq_api } from "../api/faq";
import { addUrl_api, updateUrl_api } from "../api/url";
import { addImportantLinks_api, update_importantLinks_api } from "../api/importantLinks";
const AddImportantLinks = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const id = location?.state?.data?._id;
    console.log(location, "location data ")
    const [isLoading, setisLoading] = useState(false);
    const [title, settitle] = useState("");
    const [url, seturl] = useState("")
    const [desc, setDesc] = useState("")
    const pagetype = location.state.pagetype;
    useEffect(() => {
        settitle(location?.state?.data?.title);
        seturl(location?.state?.data?.url)
        setDesc(location?.state?.data?.description)
    }, [location]);

    const createFaq = async () => {
        console.log(pagetype == "Add");

        if (pagetype == "Add") {
            let temp = {
                title: title,
                url: url,
            }

            try {
                let res = await addImportantLinks_api(temp);
                if (res.data.status) {
                    navigate("/important-links");
                    notificationHandler({ type: "success", msg: res.data.message });
                } else {
                    notificationHandler({ type: "success", msg: res.data.message });
                }
            } catch (error) {
                notificationHandler({ type: "danger", msg: error.message });
                console.log(error);
            }
        }
        if (pagetype == "Edit") {
            let temp = {
                title: title,
                url: url,
            }
            try {
                let res = await update_importantLinks_api(id, temp);
                if (res.data.status) {
                    navigate("/important-links");
                    notificationHandler({ type: "success", msg: res.data.message });
                } else {
                    notificationHandler({ type: "success", msg: res.data.message });
                }
            } catch (error) {
                notificationHandler({ type: "danger", msg: error.message });
                console.log(error);
            }
        }
    };
    return (
        <>
            <div className="">
                <Card className={s["admin_container"]}>
                    <div className={s["title"]} onClick={() => navigate(-1)}>
                        <BiArrowBack />
                        Back
                    </div>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12}>
                            <div className="form-group">
                                <label for="exampleInputEmail1">Title</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="title" value={title}
                                    onChange={(e) => settitle(e.target.value)}
                                    placeholder="title"
                                />

                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className="form-group">
                                <label for="exampleInputEmail1">Url</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="url"
                                    value={url}
                                    onChange={(e) => seturl(e.target.value)}
                                    placeholder="url"
                                />
                            </div>
                        </Grid>
                        {/* <Grid item xs={12}>
                            <div className="form-group">
                                <label for="exampleInputEmail1">Url</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="desc"
                                    value={desc}
                                    onChange={(e) => setDesc(e.target.value)}
                                    placeholder="description"
                                />
                            </div>
                        </Grid> */}
                    </Grid>
                    <div className={s["form-login-btn"]} onClick={() => createFaq()}>
                        <Custombutton>Submit</Custombutton>
                    </div>
                </Card>
            </div>
        </>
    );
};

export default AddImportantLinks;
