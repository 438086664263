const getBaseUrl = () => {
  // return "http://143.110.245.179:3002/api/";
  // return "http://91.108.111.52:5000/api";
  return "http://91.108.111.52:5000/api";
};
export { getBaseUrl };

const getBaseUrl2 = () => {
  return "http://91.108.111.52:5000/";
};
export { getBaseUrl2 };
