import axios from "axios";
import Cookies from "js-cookie";
import { getBaseUrl } from "../../utils";

let get_all_list = getBaseUrl() + "/admin/getUsers";
let add_featureMedia = getBaseUrl() + "/superadmin/create";

let team_update = getBaseUrl() + "/admin/updateUser";
let assign_manager = getBaseUrl() + "/admin/assignManager";

// update image url 
let update_image_url = getBaseUrl() + "/admin/updateUrlImageUpload"
let update_image_url_for_manager = getBaseUrl() + "/admin/updateUrlImageUploadForManager"

let get_lead_by_id = getBaseUrl() + "/admin/getLeadById/"
let get_lead_by_id_for_admin = getBaseUrl() + "/admin/getLeadByIdForAdmin/"

let get_single_user = getBaseUrl() + "/admin/getUserById/"






export const get_all_admin_api = async (data) => {
  let config = {
    method: "post",
    url: get_all_list,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data
  };
  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const add_feature_media_api = async (data) => {
  let config = {
    method: "post",
    url: add_featureMedia,
    headers: { Authorization: `Bearer${Cookies.get("token")}` },
    data: data,
  };
  try {
    let res = axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
//  used in the zamoney rise 
export const update_Team_api = (data, id) => {
  let config = {
    method: "patch",
    url: team_update + `/${id}`,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };
  try {
    let res = axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const assignManager_api = (data) => {
  let config = {
    method: "post",
    url: assign_manager,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };
  try {
    let res = axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};






// this is used to update the image status of the leads

export const getLeadData_api = async (data) => {
  let config = {
    method: "get",
    url: get_lead_by_id + `${data}`,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
  };
  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};


// THis api is used to het the single lead for the admin 
export const getLeadDataForAdmin_api = async (data) => {
  let config = {
    method: "get",
    url: get_lead_by_id_for_admin + `${data}`,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
  };
  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};





export const update_imageUrlStatus_api = (data, id) => {
  let config = {
    method: "patch",
    url: update_image_url + `/${id}`,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };
  try {
    let res = axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};


export const update_imageUrlStatusForManager_api = (data, id) => {
  let config = {
    method: "patch",
    url: update_image_url_for_manager + `/${id}`,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    data: data,
  };
  try {
    let res = axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

//  this api is used to shw the data of a single user
export const getSingleUser_api = async (data) => {
  let config = {
    method: "get",
    url: get_single_user + `${data}`,
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
  };
  try {
    let res = await axios(config);
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};