import axios from "axios";
import { getBaseUrl } from "../../utils";
import Cookies from "js-cookie";

const dash_list = getBaseUrl() + "/admin/dashboard"
const manager_data_list = getBaseUrl() + "/admin/managerDashboard"

export const get_dash_list_api = async () => {
    let config = {
        method: "get",
        url: dash_list,
        headers: { Authorization: `Bearer ${Cookies.get("token")}` }
    }
    try {
        let res = await axios(config)
        return res;
    } catch (error) {
        console.log(error);
        return error.response
    }
}
export const get_dash_manager_list_api = async () => {
    let config = {
        method: "get",
        url: manager_data_list,
        headers: { Authorization: `Bearer ${Cookies.get("token")}` }
    }
    try {
        let res = await axios(config)
        return res;
    } catch (error) {
        console.log(error);
        return error.response
    }
}