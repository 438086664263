import { Card } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FiUsers } from "react-icons/fi";
import s from "./dashboard.module.css";
import Graph from "./Graph";
import Graph1 from "./Graph1";
import { get_dash_list_api, get_dash_manager_list_api } from "../api/dashboard";
import Loder from "../../Loder/Loder";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
const Home = () => {

  const [dashboardData, setDashboardData] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const location = useLocation()
  const role = location?.state?.role || Cookies.get("role")
  console.log(location?.state?.role, dashboardData, "this is the identification of the roles")

  // const cardArray = [
  //   {
  //     title: "Total Lead",
  //     icon: "2994",
  //   },
  //   {
  //     title: "Active Lead",
  //     icon: "1500",
  //   },
  //   {
  //     title: "Success",
  //     icon: "1000",
  //   },
  //   {
  //     title: "Rejected",
  //     icon: "494",
  //   },
  // ];
  useEffect(() => {
    if (role == "Admin") {
      getAllDashFunc();
    }
    if (role == "Manager") {
      getAllDashManagerFunc();
    }
  }, [])

  const getAllDashFunc = async () => {
    setIsLoading(true)
    try {
      let res = await get_dash_list_api()
      if (res?.data?.status) {
        setDashboardData(res?.data.data)
        setIsLoading(false)
      } else {
        setIsLoading(false)
        console.log("status false")
      }
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  }
  const getAllDashManagerFunc = async () => {
    setIsLoading(true)
    try {
      let res = await get_dash_manager_list_api()
      console.log(res.data.data)
      if (res?.data?.status) {
        setDashboardData(res?.data.data)
        setIsLoading(false)
      } else {
        setIsLoading(false)
        console.log("status false")
      }
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  }
  return (
    <>
      <div style={{ paddingInline: "1rem" }}>
        <div className="dashboard-section">
          {
            role === "Manager" ?
              <div className={s["dashboard-header-container"]}>
                <Card className={s["dashbord-content"]}>
                  <div class={s["dashboard-main-card"]}>
                    <div class={s["dashboard-card-content"]}>
                      <div class={s["card-title"]}>
                        <h4>Today Lead</h4>
                        <h5>{dashboardData?.todayLeads}</h5>
                      </div>
                    </div>
                  </div>
                </Card>
                <Card className={s["dashbord-content"]}>
                  <div class={s["dashboard-main-card"]}>
                    <div class={s["dashboard-card-content"]}>
                      <div class={s["card-title"]}>
                        <h4>Month Leads</h4>
                        <h5>{dashboardData?.monthLeads}</h5>
                      </div>
                    </div>
                  </div>
                </Card>
                <Card className={s["dashbord-content"]}>
                  <div class={s["dashboard-main-card"]}>
                    <div class={s["dashboard-card-content"]}>
                      <div class={s["card-title"]}>
                        <h4>Total Leads</h4>
                        <h5>{dashboardData?.totalLeads}</h5>
                      </div>
                    </div>
                  </div>
                </Card>
                <Card className={s["dashbord-content"]}>
                  <div class={s["dashboard-main-card"]}>
                    <div class={s["dashboard-card-content"]}>
                      <div class={s["card-title"]}>
                        <h4>Total Members</h4>
                        <h5>{dashboardData?.memebersCount}</h5>
                      </div>
                    </div>
                  </div>
                </Card>
                {/* <Card className={s["dashbord-content"]}>
                  <div class={s["dashboard-main-card"]}>
                    <div class={s["dashboard-card-content"]}>
                      <div class={s["card-title"]}>
                        <h4>Team Count</h4>
                        <h5>{dashboardData?.managerCount}</h5>
                      </div>
                    </div>
                  </div>
                </Card> */}
              </div>
              :
              <div className={s["dashboard-header-container"]}>
                {/* {dashboardData.map((data) => ( */}
                <Card className={s["dashbord-content"]}>
                  <div class={s["dashboard-main-card"]}>
                    <div class={s["dashboard-card-content"]}>
                      <div class={s["card-title"]}>
                        <h4>Today Lead</h4>
                        <h5>{dashboardData?.todayLeads}</h5>
                      </div>
                    </div>
                  </div>
                </Card>
                <Card className={s["dashbord-content"]}>
                  <div class={s["dashboard-main-card"]}>
                    <div class={s["dashboard-card-content"]}>
                      <div class={s["card-title"]}>
                        <h4>Month Leads</h4>
                        <h5>{dashboardData?.monthLeads}</h5>
                      </div>
                    </div>
                  </div>
                </Card>
                <Card className={s["dashbord-content"]}>
                  <div class={s["dashboard-main-card"]}>
                    <div class={s["dashboard-card-content"]}>
                      <div class={s["card-title"]}>
                        <h4>Total Leads</h4>
                        <h5>{dashboardData?.totalLeads}</h5>
                      </div>
                    </div>
                  </div>
                </Card>
                <Card className={s["dashbord-content"]}>
                  <div class={s["dashboard-main-card"]}>
                    <div class={s["dashboard-card-content"]}>
                      <div class={s["card-title"]}>
                        <h4>Total Users</h4>
                        <h5>{dashboardData?.totalUsers}</h5>
                      </div>
                    </div>
                  </div>
                </Card>
                <Card className={s["dashbord-content"]}>
                  <div class={s["dashboard-main-card"]}>
                    <div class={s["dashboard-card-content"]}>
                      <div class={s["card-title"]}>
                        <h4>Team Count</h4>
                        <h5>{dashboardData?.managerCount}</h5>
                      </div>
                    </div>
                  </div>
                </Card>
                <Card className={s["dashbord-content"]}>
                  <div class={s["dashboard-main-card"]}>
                    <div class={s["dashboard-card-content"]}>
                      <div class={s["card-title"]}>
                        <h4>Total Employees</h4>
                        <h5>{dashboardData?.totalUsers - dashboardData?.managerCount}</h5>
                      </div>
                    </div>
                  </div>
                </Card>
                {/* ))} */}
              </div>
          }
        </div>
        {role == "Admin" &&
          <div className={s["graph-container"]}>
            <div className={s["graph-content"]}>
              <div className="graph-left" style={{ width: "70%" }}>
                <Graph1 />
              </div>
              {/* <div className="graph-right" style={{ width: "30%" }}>
             <Graph />
           </div> */}
            </div>
          </div>
        }

      </div>
      <Loder loading={isLoading} />
    </>
  );
};

export default Home;
