import axios from "axios";
import { getBaseUrl } from "../../utils";
import Cookies from "js-cookie";


let important_links = getBaseUrl() + "/admin/getLinks"
const deleteImportantLinks = getBaseUrl() + "/admin/deleteLink";
const addImportantLinks = getBaseUrl() + "/admin/createLink";
const updateImportantLinks = getBaseUrl() + "/admin/updateLink";




export const getAllImportantLinks_api = async (data) => {
    let config = {
        method: "get",
        url: important_links,
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
        data: data
    }

    try {
        let res = await axios(config)
        return res;
    } catch (error) {
        console.log(error)
        return error.response;
    }
}



export const update_importantLinks_api = async (id, data) => {
    let config = {
        method: "patch",
        url: updateImportantLinks + `/${id}`,
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
        data: data,
    };

    try {
        let res = await axios(config);
        return res;
    } catch (error) {
        console.log(error);
        return error.response;
    }
};
export const addImportantLinks_api = async (data) => {
    let config = {
        method: "post",
        url: addImportantLinks,
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token") || Cookies.get("token")}` },
        data: data,
    };
    try {
        let res = await axios(config);
        return res;
    } catch (error) {
        console.log(error);
        return error.response;
    }
};

export const deleteImportantLinks_api = async (data) => {
    let config = {
        method: "delete",
        url: deleteImportantLinks + `/${data.id}`,
        headers: { Authorization: `Bearer ${sessionStorage.getItem("token") || Cookies.get("token")}` },
        // data: { faqId: data },
    };
    try {
        let res = await axios(config);
        return res;
    } catch (error) {
        console.log(error);
        return error.response;
    }
};