import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import Custombutton from "../../Common/Custombutton";
import { notificationHandler } from "../../utils/Notification";
import {
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
} from "@mui/material";
import { add_tag_api, update_tag_api } from "../api/softlink";
import s from "./softlink.module.css";

const AddSoftLink = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  let pagetype = location?.state?.pagetype;

  useEffect(() => {
    setName(location?.state?.data?.name);
  }, [location]);

  const create_user = async () => {
    if (pagetype === "Add") {
      let temp = {
        name: name,
      };
      try {
        let res = await add_tag_api(temp);
        if (res.data.status) {
          navigate("/tag-list");
          notificationHandler({ type: "success", msg: res?.data?.message });
        } else {
          notificationHandler({ type: "success", msg: res?.data?.message });
        }
      } catch (error) {
        notificationHandler({ type: "danger", msg: error?.message });
        console.log(error);
      }
    }
    if (pagetype == "Edit") {
      let temp = {
        name: name,
      };
      // try {
      //   let res = await update_tag_api(temp, location?.state?.data?._id);
      //   if (res.data.status) {
      //     navigate("/tag-list");
      //     notificationHandler({ type: "success", msg: res.data.message });
      //   } else {
      //     notificationHandler({ type: "success", msg: res.data.message });
      //   }
      // } catch (error) {
      //   notificationHandler({ type: "danger", msg: error.message });
      //   console.log(error);
      // }
    }
  };

  return (
    <>
      <div className="">
        <Card className={s["admin_container"]}>
          <div className={s["title"]} onClick={() => navigate(-1)}>
            <IoArrowBack size={25} /> Back
          </div>
          <Grid
            xs={12}
            conatiner
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={6}>
              <div className="form-group">
                <label htmlFor="">Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="name....."
                />
              </div>
            </Grid>
          </Grid>
          <div className={s["form-login-btn"]} onClick={() => create_user()}>
            <Custombutton>Submit</Custombutton>
          </div>
        </Card>
      </div>
    </>
  );
};

export default AddSoftLink;
