import React, { useState } from "react";
import { RxDashboard } from "react-icons/rx";
import s from "./sidebar.module.css";
import { FiUsers } from "react-icons/fi";
import { MdOutlineArticle, MdEvent } from "react-icons/md";
import { RiCommunityLine } from "react-icons/ri";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { SiOpenbadges } from "react-icons/si";
import { MdOutlineAdminPanelSettings } from "react-icons/md";
import { PiTagBold } from "react-icons/pi";
import logo from "../../assets/logo/logo.png";
import { useNavigate } from "react-router-dom";
import { RiAdminLine } from "react-icons/ri";
import { RiChatFollowUpLine } from "react-icons/ri";
import Cookies from "js-cookie";
const Sidebar = ({ open }) => {
  const navigate = useNavigate();
  const role = Cookies.get("role")
  console.log(Cookies.get("role"), "role get from the cookies set  in the browser")
  const [menuItems, setmenuItems] = useState([
    {
      title: "Dashboard",
      icons: <RxDashboard size={20} />,
      navigate: "dashboard",
      show: false,
      subItems: [],
    },
    {
      title: "Banner",
      icons: <MdEvent size={20} />,
      navigate: "banner-list",
      show: false,
      subItems: [],
    },
    {
      title: "Teams",
      icons: <MdEvent size={20} />,
      navigate: "admin-list",
      show: false,
      suItems: [],
    },
    {
      title: "Lead",
      icons: <RiAdminLine size={20} />,
      navigate: "leads",
      show: false,
      subItems: [],
    },
    {
      title: "Support",
      icons: <RiAdminLine size={20} />,
      navigate: "query",
      show: false,
      subItems: [],
    },
    {
      title: "FAQs",
      icons: <RiAdminLine size={20} />,
      navigate: "faq",
      show: false,
      subItems: [],
    },
    {
      title: "Managers",
      icons: <RiAdminLine size={20} />,
      navigate: "manager-list",
      show: false,
      subItems: [],
    },
    {
      title: "Url",
      icons: <RiAdminLine size={20} />,
      navigate: "url-list",
      show: false,
      subItems: [],
    },
    {
      title: "Soft Leads",
      icons: <PiTagBold size={20} />,
      navigate: "soft-link-list",
      show: false,
      subItems: [],
    },

    // {
    //   title: "Lead Follow Up",
    //   icons: <RiChatFollowUpLine size={20} />,
    //   navigate: "followup-list",
    // },
    {
      title: "Important Links",
      icons: <MdEvent size={20} />,
      navigate: "important-links",
      show: false,
      subItems: [],
    },
    {
      title: "Message",
      icons: <MdEvent size={20} />,
      navigate: "message",
      show: false,
      subItems: [],
    },

    // {
    //   title: "Message",
    //   icons: <PiTagBold size={20} />,
    //   navigate: "message",
    //   show: false,
    //   subItems: [],
    // },
  ]);

  const [managerList, setmanagerList] = useState([
    {
      title: "Dashboard",
      icons: <RxDashboard size={20} />,
      navigate: "dashboard",
      show: false,
      subItems: [],
    },
    {
      title: "Manager Team",
      icons: <PiTagBold size={20} />,
      navigate: "manager-team-list",
      show: false,
      subItems: []
    },
    {
      title: "Lead",
      icons: <PiTagBold size={20} />,
      navigate: "manager-dashboard-lead",
      show: false,
      subItems: []
    },
    {
      title: "Support",
      icons: <PiTagBold size={20} />,
      navigate: "manager-support",
      show: false,
      subItems: []
    },
  ]);
  return (
    <>
      <section className={s["sidebar"]}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            // paddingBlock: "18px",
            background: "#5551D2",
          }}
          className={`${s["collapsed-logo"]} ${open ? `${s["logo-section"]}` : `${s["logo-section-hide"]}`
            }`}
        >
          <img
            onClick={() => navigate("/dashboard")}
            src={logo}
            alt="logo"
            draggable="false"
            style={{ width: "55%", cursor: "pointer" }}
          />
        </div>
        {role === "Admin" ? (menuItems.map((data, i) => (
          <div key={i}>
            <div
              className={s["sidebar-content"]}
              onClick={() => navigate(`/${data?.navigate}`)}
            >
              <div className={s["sidebar-item"]}>
                <div className="sidebaricons">{data.icons}</div>
                <div
                  className={
                    open ? `${s["sidebar-title"]}` : `${s["sidebar-title-hide"]}`
                  }
                >
                  {data.title}
                </div>
              </div>
            </div>
          </div>
        ))) : (managerList.map((data, i) => (
          <div key={i}>
            <div
              className={s["sidebar-content"]}
              onClick={() => navigate(`/${data?.navigate}`)}
            >
              <div className={s["sidebar-item"]}>
                <div className="sidebaricons">{data?.icons}</div>
                <div
                  className={
                    open ? `${s["sidebar-title"]}` : `${s["sidebar-title-hide"]}`
                  }
                >
                  {data.title}
                </div>
              </div>
            </div>
          </div>
        )))}
      </section>
    </>
  );
};
export default Sidebar;
