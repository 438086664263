import React, { useEffect, useState } from "react";
import { MdDelete } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { tableCellClasses } from "@mui/material/TableCell";
import s from "./manager.module.css";
import { useLocation } from "react-router-dom";
import { getBaseUrl } from "../../utils";

const TeamList = () => {


    const location = useLocation()
    const data = location?.state?.data

    console.log(data)

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "var(--clr-theme)",
            color: theme.palette.common.white,
            fontWeight: "bold",
            borderRight: "1px solid #fff",
            overflow: "hidden",
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            border: "none",
            borderLeft: "2px solid #00000011",
            "&:last-child": {
                borderRight: "2px solid #00000011",
            },
        },
    }));
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        borderBottom: "2px solid #00000011",
    }));

    return (
        <div className="">
            <div className="beat_heading">
                <div className={s["user-list-heading"]}>
                    <div className="user-list-title">
                        <h3>Team List </h3>
                    </div>
                </div>
                <div className="beat_left">
                </div>
            </div>
            <div className="beat_table">
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center">Profile</StyledTableCell>
                            <StyledTableCell align="center">Members</StyledTableCell>
                            <StyledTableCell align="center">Phone No.</StyledTableCell>
                            <StyledTableCell align="center">mail</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.manager?.employees?.map((row, index) => (
                            console.log(row, "row data in the listing of the manager"),
                            <StyledTableRow key={row.id}>
                                <StyledTableCell>
                                    <img src={getBaseUrl() + row?.profilePic} alt="thumbnail" style={{ width: "50px", height: "50px" }} />
                                </StyledTableCell>
                                <StyledTableCell>{row?.fullName}</StyledTableCell>
                                <StyledTableCell>{row?.mobileNo} </StyledTableCell>
                                <StyledTableCell align="center">
                                    {row?.email}
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        </div >
    );
};

export default TeamList;

