import React from 'react'
import UrlList from '../components/Url/UrlListing'

const UrlPage = () => {
    return (
        <div>
            <UrlList />
        </div>
    )
}

export default UrlPage
