import xlsx from "json-as-xlsx";
import { useEffect } from "react";
import { toast } from "react-toastify";

let settings = {
    fileName: "Zamoneyrise", // Name of the resulting spreadsheet
    extraLength: 3, // A bigger number means that columns will be wider
    writeMode: "writeFile", // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
    writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
    RTL: false, // Display the columns from right-to-left (the default value is false)
};

const ExportToExcel = ({ data }) => {
    console.log(data, "data o  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>..on")
    useEffect(() => {
        exportFunc(data);
    }, [data]);

    const exportFunc = (allLeadsData) => {

        console.log(allLeadsData, "allLeadsData in rhe export function of the excel sheet")
        if (allLeadsData.length < 1) {
            return toast.error("All lead list is empty!");
        }

        let data = [
            {
                sheet: "Users Data",
                columns: [
                    { label: "ID", value: row => row._id ? row._id : "" },
                    { label: "Agent Name", value: row => row._id ? row._id : "" },
                    { label: "User Name", value: row => row.fullName ? row.fullName : "" },
                    { label: " Aadhaar Card No", value: row => row.aadhaarCardNo ? row.aadhaarCardNo : "" },
                    { label: "Email", value: row => row.email ? row.email : "" },
                    { label: "Mobile Number", value: row => row.mobileNo ? row.mobileNo : "" },
                    { label: "Employee ID", value: row => row.employeeID ? row.employeeID : "" },
                    { label: "Status Of User", value: row => row.employeeStatus ? row.employeeStatus : "" },
                    { label: "Bank Name", value: row => row.bankName ? row.bankName : "" },
                    { label: "Account Holder Name", value: row => row.accountHolderName ? row.accountHolderName : "" },
                    { label: "Account Number", value: row => row.accountNumber ? row.accountNumber : "" },
                    { label: "I.F.S.C Code", value: row => row.ifscCode ? row.ifscCode : "" },
                    { label: "PAN Card Number", value: row => row.panCardNo ? row.panCardNo : "" },
                ],
                content: allLeadsData,
            },
        ];

        let callback = function (sheet) {
            console.log("Download complete:", sheet);
        };

        try {
            xlsx(data, settings, callback);
        } catch (error) {
            console.log(error);
        }
    };

    return null;
};

export default ExportToExcel;
